<template>
  <!-- Profile Detail -->
  <vx-card class="mt-base p-5 w-full flex flex-col">

    <div>
      <p class="font-semibold text-2xl text-black">Clinic Details</p>
    </div>

    <div class="flex flex-col md:flex-row md:justify-between">
      
      <div class="w-full md:w-1/2">
        <div class="detail_item">
          <p class="detail_label">Clinic name</p>
          <p
            class="details_field"
          >{{clinicData.clinic_name}}</p>
        </div>
        <div class="detail_item">
          <p class="detail_label">Company name</p>
          <p
            class="details_field"
          >{{clinicData.company_name}}</p>
        </div>

        <div class="detail_item">
          <p class="detail_label">ABN</p>
          <p
            class="details_field"
          >{{clinicData.abn}}</p>
        </div>

        <div class="detail_item">
          <p class="detail_label">Clinic Address</p>
          <p
            class="details_field"
          >{{clinicData.clinic_address}}</p>
        </div>
        <div class="detail_item">
          <p class="detail_label">Clinic Post Code</p>
          <p
            class="details_field"
          >{{clinicData.post_code}}</p>
        </div>

        <div class="detail_item">
          <p class="detail_label">Clinic State</p>
          <p
            class="details_field"
          >{{clinicData.clinic_state ? clinicData.clinic_state : "-"}}</p>
        </div>
      </div>

      <div class="w-full md:w-1/2 flex justify-end contact">
        <div class="w-full flex flex-col md:w-3/4 p-5 md:px-8 md:py-10 border-r-2 min-h-0">
            <p>To edit your clinic details please contact</p>
            <p class="text-sm">support@freshclinics.com.au</p>
            <p class="text-sm">1300 375 646</p>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      clinicData: {
        clinic_name: "",
        company_name: "",
        abn: "",
        clinic_address: "",
        post_code: "",
        clinic_state: "",
      },
    };
  },
  methods: {
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    async getClinicDetail(id) {
      let self = this;
      await this.fetchClinicsDetail(id).then((res) => {
        const clinic = res.data.data;
        self.clinicData.clinicId = clinic._id;
        self.clinicData.company_name = clinic.companyName;
        self.clinicData.clinic_name = clinic.clinicName;
        self.clinicData.abn = clinic.abn;
        self.clinicData.clinic_address = clinic.address.displayAddress;
        self.clinicData.post_code = clinic.postalAddress;
        self.clinicData.clinic_state = clinic.address.state;
      });
    },
  },
  async created() {
    const clinicId = sessionStorage.getItem("doctorClinicSelectedClinic");
    await this.getClinicDetail(clinicId);
  },
};
</script>

<style lang="scss">
.detail_item {
  margin: 10px 0px;
  font-size: 18px;
}
.detail_item .details_field {
  font-size: 16px;
  margin-bottom: 5px;
  width: 70%;
  color: #626262;
  background-color: white;
  font-weight: lighter;
}
.detail_item .detail_label {
  font-weight: 600;
}
.contact {
  margin: 30px 0px;
  font-size: 18px;
}
.contact div {
  background-color: #f3f5f7;
  height: fit-content;
}

.vx-card__title h4{
    font-size: 40px;
    font-weight: bold;
}
.vx-card__title{
    padding: 10px 10px 30px 0px;
}

</style>
